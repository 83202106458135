<template>
  <div
    id="labor-planning-edit-participant-modal"
    ref="laborPlanningEditParticipantModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div
          v-if="isVisible"
          class="modal-body"
        >
          <form
            v-if="initialized"
            class="m-2"
          >
            <SelectInput
              v-if="!itemToEdit.id"
              v-model="availableParticipantEmail"
              :options="userNameOptions"
              description="Choose user"
              label="User name"
              @change="fetchOnChangeUser"
            />
            <TextInput
              v-model="itemToEdit.fullName"
              label="User name"
              readonly="true"
            />
            <TextInput
              v-model="itemToEdit.email"
              label="Email"
              readonly="true"
            />
            <SelectInput
              v-model="itemToEdit.brandIds"
              :options="brandNameOptions"
              :multiple="true"
              :showAddAll="true"
              placeholder="Pick brands"
              label="Processed brands"
              description="Processed brands"
              style="height: 150px;"
            />
            <TextInput
              v-if="itemToEdit.profileLocations.length > 0"
              v-model="itemToEdit.profileLocations"
              label="Assigned locations in profile"
              readonly="true"
            />
            <SelectInput
              v-if="!isLocationPickerDisabled"
              v-model="itemToEdit.locations"
              :options="locationsOptions"
              :multiple="true"
              :showAddAll="true"
              placeholder="Pick locations"
              label="Processed LP locations"
              description="Processed LP locations"
              style="height: 150px;"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-end mt-5">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
        <div
          v-else
          class="modal-body"
        >
          <div
            class="alert alert-warning"
            role="alert"
          >
            <h6>
              <i class="bi bi-exclamation-triangle" />
              There are no available users in the system. Add a user with the required role and then add it for Labor
              Planning.
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="laborPlanningEditParticipantConfirmationModal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The participant list for Labor Planning will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import TextInput from '../common/TextInput';
import Spinner from '../common/Spinner';
import { useVuelidate } from '@vuelidate/core';
import { Modal } from 'bootstrap';
import _ from 'lodash';
import { modalViewTypes } from '@/components/constants';
import { assignLocations } from '@/components/LaborPlanning/constants';
import SelectInput from '../common/SelectInput';

const defaultItem = {
  id: null,
  brandIds: null,
  fullName: null,
  email: null,
  locations: null,
  profileLocations: null
};

export default {
  components: {
    SelectInput,
    ConfirmationModal,
    TextInput,
    Spinner,
  },
  props: [ 'availableParticipant', 'viewType' ],
  emits: [ 'successSave', 'successDelete' ],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmationModalSave: null,
      laborPlanningEditModal: null,
      initialized: false,
      availableParticipantEmail: null,
      itemToEdit: {
        id: null,
        fullName: null,
        email: null,
        brandIds: null,
        locations: null,
        profileLocations: null
      },
      originalLocations: null,
    };
  },
  computed: {
    ...mapGetters({
      brands: 'brandManagement/processedBrandNames'
    }),
    isVisible() {
      return (this.availableParticipant?.length && this.viewType === modalViewTypes.add) || this.viewType === modalViewTypes.edit;
    },
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit participant' : 'Add new participant';
    },
    isSaveButtonDisabled() {
      return !this.initialized || !this.itemToEdit.email;
    },
    brandNameOptions() {
      return _.map(this.brands, (item) => ({ key: item.id, value: item.name }));
    },
    userNameOptions() {
      return _.map(this.availableParticipant, (item) => ({ key: item.email, value: item.fullName }));
    },
    locationsOptions() {
      const availableLocations =_.map(assignLocations , (value, key) => ({ key, value: key }));
      return _.differenceBy(availableLocations, this.itemToEdit.profileLocations.map((key) => ({ key })), 'key');
    },
    isLocationPickerDisabled() {
      return this.locationsOptions.length === 0;
    },
    concatUserGroups() {
      const groups = this.itemToEdit.profileLocations.concat(this.itemToEdit.locations);
      return _.uniq(groups);
    },
    isLocationsChanged() {
      return this.originalLocations !== this.itemToEdit.locations;
    },
  },
  async mounted() {
    this.laborPlanningEditModal = new Modal(this.$refs['laborPlanningEditParticipantModal']);
    this.confirmationModalSave = this.$refs['laborPlanningEditParticipantConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchParticipantById: 'laborPlanning/fetchParticipant',
      updateParticipant: 'laborPlanning/updateParticipant',
      fetchProcessedBrandNames: 'brandManagement/fetchProcessedBrandNames',
      updateUserLocationGroups: 'dashboard/updateTableauUserGroups'
    }),
    async initModal(id) {
      this.initialized = false;
      await this.fetchProcessedBrandNames();
      this.laborPlanningEditModal.show();
      const participantToEdit = id ? (await this.fetchParticipantById(id)) : defaultItem;
      this.updateItemData(participantToEdit);
      this.initialized = true;
    },
    fetchOnChangeUser() {
      const participant = _.find(this.availableParticipant, (item) => (item.email === this.availableParticipantEmail));
      this.itemToEdit.email = participant.email;
      this.itemToEdit.id = participant.id;
      this.itemToEdit.fullName = participant.fullName;
    },
    updateItemData(newValue) {
      this.itemToEdit.id = newValue.id;
      this.itemToEdit.fullName = newValue.fullName;
      this.itemToEdit.email = newValue.email;
      this.itemToEdit.brandIds = newValue.brandIds;
      this.itemToEdit.locations = newValue.locations;
      this.availableParticipantEmail = null;
      this.itemToEdit.profileLocations = newValue.profileLocations;
      this.originalLocations = newValue.locations;
    },
    async showSaveConfirmationModalHandler() {
      this.laborPlanningEditModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.itemToEdit
      };
      try {
        if (this.isLocationsChanged) {
          await this.updateUserGroups();
        }
        await this.updateParticipant(dataToSave);
        this.$toast.success('Participant successfully updated');
        this.$emit('successSave');
      } catch (e) {
        this.$toast.error('Failed to save changes<br/>' + e);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
    async updateUserGroups() {
      const data = {
        username: this.itemToEdit.email,
        groups: _.map(this.concatUserGroups, (v) => assignLocations[v])
      };
      await this.updateUserLocationGroups(data);
    }
  }
};
</script>
