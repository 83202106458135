<template>
  <div class="container mt-5">
    <div
      v-if="isInitialised"
      class="row gx-0 d-flex justify-content-center"
    >
      <div class="card mb-5">
        <div class="card-header d-flex justify-content-between">
          <h5 class=" mt-1 mb-0">
            General settings
          </h5>
          <button
            class="btn btn-sm btn-primary"
            @click="editRuleClickHandler"
          >
            Edit
          </button>
        </div>
        <div class="card-body w-100 p-2">
          <div class="row">
            <div class="col-lg">
              <TextInput
                v-model="laborPlanningRule.hoursPerDay"
                class="p-2"
                label="Hours per day (hours)"
                readonly
              />
            </div>
            <div class="col-lg">
              <TextInput
                v-model="laborPlanningRule.osPerHourCost"
                class="p-2"
                label="OS cost per hour ($)"
                readonly
              />
            </div>
            <div class="col-lg">
              <TextInput
                v-model="laborPlanningRule.agenciesPerHourCost"
                class="p-2"
                label="Agencies cost per hour ($)"
                readonly
              />
            </div>
            <div class="col-lg">
              <TextInput
                v-model="laborPlanningRule.defaultPremiumDiscount"
                class="p-2"
                label="Default premium discount (%)"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-5">
        <div class="card-header d-flex justify-content-start">
          <h5 class=" mt-1 mb-0">
            Managers
          </h5>
        </div>
        <div class="card-body p-0 w-100">
          <div
            v-if="managers.length"
            class="card-body d-flex p-0 justify-content-center"
            style="height: 25em;"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="updatedParticipants(managers)"
              class="ag-theme-alpine h-100 w-100"
            />
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header d-flex justify-content-start">
          <h5 class=" mt-1 mb-0">
            Planners
          </h5>
        </div>
        <div class="card-body p-0 w-100">
          <div
            v-if="planners.length"
            class="card-body d-flex p-0 justify-content-center"
            style="height: 25em;"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="updatedParticipants(planners)"
              class="ag-theme-alpine h-100 w-100"
            />
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else />
  </div>
  <LaborPlanningConfigurationEditModal
    ref="laborPlanningEditRuleModal"
  />
  <LaborPlanningParticipantEditModal
    ref="laborPlanningEditParticipantModal"
    :availableParticipant="participantOptions"
    :viewType="modalViewType"
    @successSave="onParticipantSave"
    @successDelete="onParticipantSave"
  />
</template>

<script>
import _ from 'lodash';
import TextInput from '@/components/common/TextInput';
import { mapActions, mapGetters } from 'vuex';
import LaborPlanningConfigurationEditModal from './LaborPlanningConfigurationEditModal';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import LaborPlanningParticipantEditModal from './LaborPlanningParticipantEditModal';
import { modalViewTypes } from '../constants';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Spinner,
    LaborPlanningParticipantEditModal,
    LaborPlanningConfigurationEditModal,
    TextInput,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      isInitialised: false,
      participantOptions: null,
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      laborPlanningRule: 'laborPlanning/laborPlanningRule',
      managers: 'laborPlanning/managers',
      brands: 'brandManagement/processedBrandNames',
      planners: 'laborPlanning/planners'
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Edit',
            buttonVariant: 'btn-primary',
            clicked: (id) => {
              this.editParticipantClickHandler(id);
            },
            isDisabled: (rowData) => {
              return rowData.status === 'DISABLED';
            },
          },
          width: 80
        },
        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          width: 150
        },
        {
          headerName: 'User name',
          field: 'fullName',
          sortable: true,
          width: 260
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 260,
          sortable: true
        },
        {
          headerName: 'Processed brands',
          field: 'brands',
          width: 420
        },
        {
          headerName: 'Processed locations',
          field: 'locations',
          width: 420
        }
      ];
    }
  },
  async mounted() {
    this.isInitialised = false;
    await Promise.all([
      this.fetchRule(),
      this.fetchManagers(),
      this.fetchProcessedBrandNames(),
      this.fetchPlanners()
    ]);
    this.isInitialised = true;
  },
  methods: {
    ...mapActions({
      fetchRule: 'laborPlanning/fetchRule',
      fetchManagers: 'laborPlanning/fetchManagers',
      fetchProcessedBrandNames: 'brandManagement/fetchProcessedBrandNames',
      fetchAvailableManagers: 'laborPlanning/fetchAvailableManagers',
      fetchPlanners: 'laborPlanning/fetchPlanners',
      fetchAvailablePlanners: 'laborPlanning/fetchAvailablePlanners',
    }),
    editRuleClickHandler() {
      this.$refs.laborPlanningEditRuleModal.initModal();
    },
    editParticipantClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.laborPlanningEditParticipantModal.initModal(id);
    },
    onParticipantSave() {
      this.fetchManagers();
      this.fetchPlanners();
    },
    updatedParticipants(participants) {
      return participants.map((user) => {
        const brands = _.map(user.brandIds, (brandId) => {
          const brand =  _.find(this.brands, { id: brandId });
          if (brand) {
            return brand.name;
          }
          return null;
        });
        return _.merge({}, user, { brands });
      });
    }
  }
};
</script>
