<template>
  <div
    id="labor-planning-edit-rule-modal"
    ref="laborPlanningEditRuleModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit general settings
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="ruleToEdit.hoursPerDay"
              :errors="v$.ruleToEdit.hoursPerDay.$errors"
              class="p-2"
              label="Hours per day (hours)"
              @blur="v$.ruleToEdit.hoursPerDay.$touch"
            />
            <TextInput
              v-model="ruleToEdit.osPerHourCost"
              :errors="v$.ruleToEdit.osPerHourCost.$errors"
              class="p-2"
              label="OS cost per hour ($)"
              @blur="v$.ruleToEdit.osPerHourCost.$touch"
            />
            <TextInput
              v-model="ruleToEdit.agenciesPerHourCost"
              :errors="v$.ruleToEdit.agenciesPerHourCost.$errors"
              class="p-2"
              label="Agencies cost per hour ($)"
              @blur="v$.ruleToEdit.agenciesPerHourCost.$touch"
            />
            <TextInput
              v-model="ruleToEdit.defaultPremiumDiscount"
              :errors="v$.ruleToEdit.defaultPremiumDiscount.$errors"
              class="p-2"
              label="Default premium discount (%)"
              @blur="v$.ruleToEdit.defaultPremiumDiscount.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="laborPlanningEditRuleConfirmationModal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The rules for Labor Planning will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import TextInput from '../common/TextInput';
import Spinner from '../common/Spinner';
import { useVuelidate } from '@vuelidate/core';
import { Modal } from 'bootstrap';
import { decimal, minValue, required } from '@vuelidate/validators';

export default {
  components: {
    ConfirmationModal,
    TextInput,
    Spinner
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      modalViewType: null,
      confirmationModalSave: null,
      laborPlanningEditModal: null,
      initialized: false,
      ruleToEdit: {
        id: null,
        hoursPerDay: null,
        osPerHourCost: null,
        agenciesPerHourCost: null,
        defaultPremiumDiscount: null
      }
    };
  },
  validations() {
    return {
      ruleToEdit: {
        hoursPerDay: {
          required,
          decimal,
          minValue: minValue(0),
        },
        osPerHourCost: {
          required,
          decimal,
          minValue: minValue(0),
        },
        agenciesPerHourCost: {
          required,
          decimal,
          minValue: minValue(0),
        },
        defaultPremiumDiscount: {
          required,
          decimal,
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      laborPlanningRule: 'laborPlanning/laborPlanningRule'
    }),
    isSaveButtonDisabled() {
      return !this.initialized;
    },
  },
  async mounted() {
    this.laborPlanningEditModal = new Modal(this.$refs['laborPlanningEditRuleModal']);
    this.confirmationModalSave = this.$refs['laborPlanningEditRuleConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchRule: 'laborPlanning/fetchRule',
      updateRule: 'laborPlanning/updateRule',
    }),
    async initModal() {
      this.initialized = false;
      this.laborPlanningEditModal.show();
      await this.fetchRule();
      const rule = this.laborPlanningRule;
      this.updateItemData(rule);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.ruleToEdit.id = newValue.id;
      this.ruleToEdit.hoursPerDay = newValue.hoursPerDay;
      this.ruleToEdit.osPerHourCost = newValue.osPerHourCost;
      this.ruleToEdit.agenciesPerHourCost = newValue.agenciesPerHourCost;
      this.ruleToEdit.defaultPremiumDiscount = newValue.defaultPremiumDiscount;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.laborPlanningEditModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.ruleToEdit
      };
      try {
        await this.updateRule(dataToSave);
        this.$toast.success('Rule successfully updated');
      } catch (e) {
        this.$toast.error('Failed to save changes.');
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
  }
};
</script>
